// Abstract class to define shared styles.
.input-field {
	@extend .background-transition;
	@extend .p-h-xs;

	position: relative;
	border-radius: $border-radius-m;
	background-color: $white;
	border: none;
	outline: none;
	width: 100%;
	font-family: $font-family;

	&:focus {
		border: 1px solid $border-color;
	}
}

.error-shown {
	.input-field {
		background-color: transparentize($red, 0.9);
		border-color: $red;
	}
}

.text-field,
.dropdown-field {
	@extend .input-field;

	height: $input-height;
}

.text-field {
	@extend .p-t-xs;
}

.password-field {
	@extend .text-field;

	padding-right: $space-m + $space-xs;
}

.reveal-password {
	@extend .interactive;

	position: absolute;
	right: $space-xs + $space-xxs;
	top: 16px;
	height: 24px;
	cursor: pointer;
	opacity: 0.6;
}

.textarea-field {
	@extend .input-field;
	@extend .p-b-s;
	@extend .p-h-xs;

	padding-top: calc($space-s + 5px);
	width: 100% !important;
	min-height: 160px !important;
	max-height: 800px !important;
	font-family: $font-family;
}

.datetime-field {
	@extend .text-field;

	cursor: pointer;
}
