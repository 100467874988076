.request-status {
    @extend .p-h-xs;

    height: 30px;
    line-height: 30px;
    font-size: 0.65rem;
    text-align: center;
    border-radius: 15px;
    text-transform: uppercase;
    font-weight: 600;

    &.waiting {
        color: $primary;
        background-color: $light-gray;
    }

    &.ready {
        color: $white;
        background-color: $primary;
    }

    &.cancelled {
        color: $red;
        background-color: $light-gray;
    }
}

.request-buttons {
    @extend .flex-row;
    @extend .m-b-xs;

    button:first-child {
        margin-left: auto;
    }
}

.request-tag {
    @extend .p-h-xxs;
    @extend .m-r-xxs;
    @extend .m-b-xxs;

    display: inline-block;
    height: 25px;
    font-size: 0.8rem;
    background-color: $primary;
    color: $white;
    border-radius: 4px;
    font-weight: 400;
}

.request-card-user-info {
    & > div {
        display: inline-block;

        &:first-child {
            @extend .m-r-xs;

            img {
                border: 2px solid $primary;
            }
        }
    }
}
