$sizes: (
    "xxs": $space-xxs,
    "xs": $space-xs,
    "s": $space-s,
    "m": $space-m,
    "l": $space-l,
    "xl": $space-xl,
    "xxl": $space-xxl,
);

.i-b {
    display: inline-block;
}

.i-f {
    display: inline-flex;
}

// Padding
@each $key,
$value in $sizes {

    // All
    .p-#{$key} {
        padding: $value;
    }

    // Vertical axis
    .p-v-#{$key} {
        padding-top: $value;
        padding-bottom: $value;
    }

    // Horizontal axis
    .p-h-#{$key} {
        padding-left: $value;
        padding-right: $value;
    }

    // Top
    .p-t-#{$key} {
        padding-top: $value;
    }

    // Bottom
    .p-b-#{$key} {
        padding-bottom: $value;
    }

    // Left
    .p-l-#{$key} {
        padding-left: $value;
    }

    // Right
    .p-r-#{$key} {
        padding-right: $value;
    }
}

// Margin
@each $key,
$value in $sizes {

    // All
    .m-#{$key} {
        margin: $value;
    }

    // Vertical axis
    .m-v-#{$key} {
        margin-top: $value;
        margin-bottom: $value;
    }

    // Horizontal axis
    .m-h-#{$key} {
        margin-left: $value;
        margin-right: $value;
    }

    // Top
    .m-t-#{$key} {
        margin-top: $value;
    }

    // Bottom
    .m-b-#{$key} {
        margin-bottom: $value;
    }

    // Left
    .m-l-#{$key} {
        margin-left: $value;
    }

    // Right
    .m-r-#{$key} {
        margin-right: $value;
    }
}

.m-a {
    margin: auto;
}

.m-h-a {
    margin: 0 auto;
}

.m-v-a {
    margin: auto 0;
}

.m-l-a {
    margin-left: auto;
}

.m-r-a {
    margin-right: auto;
}

.m-t-a {
    margin-top: auto;
}

.m-b-a {
    margin-bottom: auto;
}

// Grid system for desktop (e.g. col-6), tablet (e.g. col-t-3) and mobile (e.g. col-m-12)
$grid__cols: 12;

@for $i from 1 through $grid__cols {
    .col-#{$i} {
        display: inline-block;
        vertical-align: top;
        width: calc(100 / calc($grid__cols / $i) - 1.1) * 1%;
        margin-left: 1.1%;

        &:first-child {
            margin-left: 0;
        }
    }

    .col-12 {
        margin-left: 0;
    }
}

@include tablet {
    @for $i from 1 through $grid__cols {
        .col-t-#{$i} {
            display: inline-block;
            vertical-align: top;
            width: calc(100 / calc($grid__cols / $i) - 1.1) * 1%;
            margin-left: 1.1%;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .col-t-12 {
        margin-left: 0;
    }
}

@include mobile {
    @for $i from 1 through $grid__cols {
        .col-m-#{$i} {
            display: inline-block;
            vertical-align: top;
            width: calc(100 / calc($grid__cols / $i) - 1.1) * 1%;
            margin-left: 1.1%;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .col-m-12 {
        margin-left: 0;
    }
}