.toggle {
    cursor: pointer;
    display: inline-block;
}

.toggle-switch {
    display: inline-block;
    background: $gray;
    border-radius: 16px;
    border: none;
    width: 48px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;

    &:before,
    &:after {
        content: "";
    }

    &:before {
        position: absolute;
        top: 3px;
        left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        transition: left $transition-duration;
        font-size: 0.72rem;
        font-weight: 800;
        color: $gray;
    }

    .toggle-checkbox:checked + & {
        background: $primary;

        &:before {
            left: 19px;
            // content: "✓";
        }
    }
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    position: relative;
    margin-left: 5px;
    top: 2px;
}
