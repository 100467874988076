h1,
.h1,
h1 > .primary-color-text,
.h1 > .primary-color-text {
    font-size: 4rem;
	font-weight: 800;
}

h2,
.h2,
h2 > .primary-color-text,
.h2 > .primary-color-text {
    font-size: 2.4rem;
}

h3,
.h3,
h3 > .primary-color-text,
.h3 > .primary-color-text {
    font-size: 1.8rem;
}

h4,
.h4,
h4 > .primary-color-text,
.h4 > .primary-color-text {
    font-size: 1.4rem;
}

h5,
.h5,
h5 > .primary-color-text,
.h5 > .primary-color-text {
    font-size: 1.2rem;
}

h6,
.h6,
h6 > .primary-color-text,
.h6 > .primary-color-text {
    font-size: 1rem;
}

@include mobile {
    h1,
    .h1,
	h1 > .primary-color-text,
	.h1 > .primary-color-text {
        font-size: 2.4rem;
    }

    h2,
    .h2,
	h2 > .primary-color-text,
	.h2 > .primary-color-text {
        font-size: 1.8rem;
    }

    h3,
    .h3,
	h3 > .primary-color-text,
	.h3 > .primary-color-text {
        font-size: 1.4rem;
    }

    h4,
    .h4,
	h4 > .primary-color-text,
	.h4 > .primary-color-text {
        font-size: 1.2rem;
    }

    h5,
    .h5,
	h5 > .primary-color-text,
	.h5 > .primary-color-text {
        font-size: 1rem;
    }

    h6,
    .h6,
	h6 > .primary-color-text,
	.h6 > .primary-color-text {
        font-size: 0.9rem;
    }
}
