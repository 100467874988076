::-webkit-scrollbar {
    width: $space-xs;
}

// Track
::-webkit-scrollbar-track {
    background: $light-gray;
}

// Handle
::-webkit-scrollbar-thumb {
    background: $light-gray;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
    background: $light-gray;
}
