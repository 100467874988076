.social-share-container {
	@extend .flex-distributed-row;

	display: flex!important;

	img {
		@extend .interactive;
		
		display: inline-block;
		width: 30px;
		height: 30px;
	}
}
