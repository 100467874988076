.modal-container {
    @extend .flex-center;
    @extend .fixed-overlay;

    z-index: 2000;

    .modal {
        @extend .card;
        @extend .p-s;

        position: relative;
        width: calc(100% - 40px);
        max-width: 600px;
        max-height: calc(100vh - 40px);
        overflow-y: auto;
        z-index: 2001;

        .modal-header {
            @extend .flex-row;

            h4 {
                @extend .p-t-xxs;
                @extend .m-r-xxs;

                display: inline-block;
                width: calc(100% - 30px);
            }

            .close-button {
                @extend .interactive;
                @extend .flex-center;

                display: inline-flex;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background-color: $primary;
                border: none;
                outline: none;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.curtain {
    @extend .fixed-overlay;

    background-color: transparentize($black, 0.6);
    z-index: 1001;
}

.prompt {
    .modal {
        max-width: 400px;
    }
}

.ips-modal-form-container {
    @extend .p-s;

    background-color: $extra-light-primary;
    border-radius: $border-radius-m;
}
