$pagination-height: 50px;

.pagination-container {
	@extend .m-t-m;

	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	width: 100%;

	.item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
		font-size: 14px;
		border-bottom: 1px solid transparent;

		cursor: pointer;
	}

	.disabled-page {
		color: #808e9b;
	}

	.active {
		color: $white;
		border-color: $white;
	}

	.previous,
	.next {
		@extend .interactive;
		@extend .flex-center;

		position: absolute;
		height: $pagination-height;
		width: $pagination-height;

		a {
			display: block;
			height: 14px;

			img {
				height: 14px;
			}
		}
	}

	.next {
		border-left: solid 1px transparentize($white, 0.6);
		right: 0;
	}

	.previous {
		border-right: solid 1px transparentize($white, 0.6);
		left: 0;
	}

	.pagination {
		@extend .flex-center;

		position: relative;
		background-color: $primary;
		flex-direction: row;
		height: $pagination-height;
		list-style-type: none;
		border-radius: calc($pagination-height / 2);
		max-width: 40%;
		min-width: 305px;
		padding: 0 50px;
		user-select: none;

		@include mobile {
			max-width: 100%;
		}
	}

	.pagination-page {
		@extend .interactive;

		a {
			color: $white !important;
		}
	}

	.break-me {
		a {
			color: $white !important;
		}
	}
}
