@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile}) and (max-width: #{$desktop - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile - 1px}) {
        @content;
    }
}
