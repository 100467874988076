.date-time-picker {
    @extend .m-t-s;

    .field-label {
        top: -20px;
        left: 0;
    }

    // react-datetime
    .rdt {
        input {
            @extend .text-field;

            cursor: pointer;
        }

        .rdtPicker {
            padding: 0;
            border-radius: $border-radius-s;
            margin-top: 1px;

            .rdtTime {
                .rdtSwitch {
                    cursor: pointer;

                    &:hover {
                        background: #eee;
                    }
                }
            }

            table {
                border-collapse: collapse;

                thead {
                    tr {
                        height: 40px;

                        .rdtPrev,
                        .rdtNext {
                            line-height: 40px;
                        }
                    }
                }

                tbody {
                    td {
                        @extend .p-h-xxs;
                        @extend .p-v-xs;

                        .rdtCounter {
                            @extend .m-b-xs;

                            .rdtCount {
                                line-height: 20px;
                            }

                            .rdtBtn {
                                font-size: 0.85rem;
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        height: 40px;
                    }
                }
            }
        }
    }
}
