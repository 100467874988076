.avatar-container {
	width: 70%;
	max-width: 200px;

	.avatar-image {
		@extend .standard-background-image;

		width: calc(100% - 6px);
		border: 3px solid $primary;
		aspect-ratio: 1 / 1;
		border-radius: 50%;
	}
}

.avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
