.btn {
    @extend .interactive;
    @extend .p-h-s;
    @extend .flex-center;
    // This was added additionally.
    @extend .limited-text;

    position: relative;
    display: inline-block; // This was inline-flex but was changed because of text-ellipsis.
    height: $button-height;
    line-height: calc($button-height - 4px);
    background-color: $black;
    color: $white;
    text-align: center;
    border-radius: calc($button-height / 2);
    overflow: hidden;
    cursor: pointer;
    outline: none;
    font-weight: 600;
    font-size: 0.85rem;
    border: 2px solid $black;
    text-decoration: none;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    span {
        color: inherit;
    }

    &.spinning {
        @extend .p-l-m;

        .spinner {
            position: absolute;
            display: inline-flex;
            top: 10px;
            left: 8px;
        }
    }

    &.primary {
        background-color: $primary;
        border-color: $primary;

        &.negative {
            color: $primary !important;
        }
    }

    &.orange {
        background-color: $orange;
        border-color: $orange;

        &.negative {
            color: $orange !important;
        }
    }

    &.blue {
        background-color: $blue;
        border-color: $blue;

        &.negative {
            color: $blue !important;
        }
    }

    &.red {
        background-color: $red;
        border-color: $red;

        &.negative {
            color: $red !important;
        }
    }

    &.green {
        background-color: $green;
        border-color: $green;

        &.negative {
            color: $green !important;
        }
    }

    &.gray {
        background-color: $gray;
        border-color: $gray;

        &.negative {
            color: $gray !important;
        }
    }

    &.light-gray {
        background-color: $light-gray;
        border-color: $light-gray;

        &.negative {
            color: $light-gray !important;
        }
    }

    &.cream-white {
        background-color: $light-gray;
        border-color: $light-gray;

        &.negative {
            color: $light-gray !important;
        }
    }

    &.white {
        background-color: $white;
        border-color: $white;
        color: $text-color;

        &.negative {
            color: $white !important;
        }
    }

    &.negative {
        color: $black;
        background: transparent;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.3;
        transition: none;
        user-select: none;
        cursor: initial;
    }

    &.disabled {
        opacity: 0.3;
        transition: none;
        user-select: none;
        cursor: initial;
        pointer-events: none; // This is added in order to disable events through CSS.
    }
}

.full-width-button {
    .btn {
        width: 100%;
    }
}

.badge-button {
    .btn {
        min-width: 0;
        width: $button-height;
    }
}

// Multi-button
.multi-btn {
    @extend .flex-row;

    display: inline-flex;
    overflow: hidden;
    border-radius: calc($button-height / 2);

    .btn {
        border-radius: 0;
        margin: 0;
    }
}

// Search button
.search-button {
    @extend .interactive;

    height: calc($input-height);
    width: $input-height;
    background-color: $primary;
    color: $white;
    cursor: pointer;
    border-radius: 0 $border-radius-m $border-radius-m 0;
    outline: none;
    border: none;

    img {
        width: 18px;
    }
}
