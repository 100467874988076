.radio-container {
    .radio {
        @extend .m-r-s;
        @extend .m-b-s;

        position: relative;
        display: inline-flex;
        cursor: pointer;
        align-items: center;

        .option-text {
            font-weight: 600;
            font-size: $component-font-rem;
        }

        .inner-label {
            @extend .m-r-s;

            position: relative;
            display: inline-block;
            background-color: $white;
            min-width: $toggle-button-outer-size; // Outer size
            width: $toggle-button-outer-size; // Outer size
            height: $toggle-button-outer-size; // Outer size
            border: $toggle-button-border-width solid $light-gray;
            border-radius: 50%;
            -webkit-tap-highlight-color: transparent;

            &:after {
                content: "";
                position: absolute;
                top: $toggle-button-inner-offset; // Inner offset
                left: $toggle-button-inner-offset; // Inner offset
                width: $toggle-button-inner-size; // Inner size
                height: $toggle-button-inner-size; // Inner size
                border-radius: 100%;
                background: $green;
                transform: scale(0);
                transition: all $transition-duration ease;
                opacity: 0.08;
                pointer-events: none;
            }
        }

        &:hover {
            .inner-label:after {
                transform: scale(2);
            }
        }
    }

    input[type="radio"] {
        display: none;
    }

    input[type="radio"]:focus + .inner-label:after {
        transform: scale(2);
    }

    input[type="radio"]:checked + .inner-label {
        border-color: $gray;

        &:after {
            transform: scale(1);
            transition: all $transition-duration cubic-bezier(0.35, 0.9, 0.4, 0.9);
            opacity: 1;
        }
    }
}
