.data-table {
	background-color: $white;
	width: 100%;
	overflow: hidden;
	font-size: 0.9rem;

	.data-table-header {
		@extend .p-h-xs;

		border-bottom: 1px solid $light-gray;
		overflow-x: auto;
		height: 61px; // TODO: Remove hotfix.

		.column {
			@extend .p-v-s;
			@extend .p-h-xs;

			display: inline-block;
			min-width: min-content;

			> * {
				font-weight: 600;
			}
		}
	}

	.data-table-body {
		.row {
			@extend .background-transition;

			cursor: pointer;
			overflow-x: auto;

			&:nth-child(even) {
				background-color: $cream-white;
			}

			.row-content {
				@extend .p-h-xs;

				display: flex;
				align-items: center;
				min-height: 61px;

				&:hover {
					background-color: transparentize($primary, 0.9);
				}

				.column {
					@extend .p-xs;

					display: inline-block;
					min-width: min-content;
				}
			}

			.row-subcontent {
				@extend .p-h-xs;
				@extend .max-height-transition;

				max-height: 0;
				overflow: hidden;
				background-color: $white;

				&.active {
					// max-height: 820px; // TODO: Hardcoding this should be avoided.
					max-height: 1820px; // TODO: Hardcoding this should be avoided.
				}

				.subcontent-card {
					@extend .p-s;
					@extend .m-b-xs;

					background-color: $extra-light-primary;
					border-radius: $border-radius-m;
				}
			}
		}

		.empty-table-content {
			@extend .flex-center;

			height: 150px;
		}
	}
}
