.auth-page {
	@extend .p-v-l;
	
	height: 100vh;

	.auth-form {
		width: 80%;
		max-width: 350px;

		.disclaimer {
			font-size: 0.85rem;
		}
	}
}