// Global
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;

	// Firefox scrollbar
	scrollbar-color: transparentize($text-color, 0.4) $white !important;
	scrollbar-width: thin !important;
}

:root {
	// For tables
	--rs-table-sort: #ff5a5f;
}

html {
	font-size: $font-size-desktop;
}

body {
	background-color: $light-gray;
	font-family: $font-family !important;
	min-width: $body-min-width;
	-webkit-tap-highlight-color: transparent;
	min-width: 360px;
	height: 600px;
}

// Chrome and Edge scrollbar
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: transparentize($text-color, 0.4);
	border-radius: 4px;
}

[placeholder] {
	text-overflow: ellipsis;
}

// react-tabs
.react-tabs {
	border-radius: 0 0 $border-radius-m $border-radius-m;
	overflow: hidden;

	.react-tabs__tab-list {
		border: none;
		margin-bottom: 0;
	}

	.react-tabs__tab {
		@extend .p-s;

		&:focus:after {
			display: none;
		}

		&--selected {
			background: $white;
			border: none;
			border-radius: $border-radius-m $border-radius-m 0 0;
		}
	}

	.react-tabs__tab-panel {
		background-color: $white;
	}
}

// multiselect
.css-b62m3t-container {
	width: calc(100% - 56px);
}
