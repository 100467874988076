.burger-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    width: 90px;
    position: relative;
  }
  
  .burger-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 40px;
    top: 16px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  
  .burger-container .hamburger-lines {
    display: block;
    height: 32px;
    width: 38px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .burger-container .hamburger-lines .line {
    display: block;
    height: 8px;
    width: 100%;
    border-radius: 4px;
    background: #0e2431;
  }
  
  .burger-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }
  
  .burger-container .hamburger-lines .line2 {
    width: 70%;
    transition: transform 0.2s ease-in-out;
  }
  
  .burger-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }
  
  .burger-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }
  
  .burger-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }
  
  .burger-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }