.dropdown-container {
	position: relative;

	.select-wrapper {
		position: relative;

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			text-indent: 1px;
			cursor: pointer;
			background: transparent;
			outline: none;
			height: $button-height;
			border: 1px solid $border-color;
			border-radius: calc($button-height / 2);
			color: $border-color;

			&::-ms-expand {
				display: none;
			}
		}

		img {
			position: absolute;
			right: $space-xs;
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;
			margin: 0;
		}

		&.white {
			select {
				border-color: $white;
				color: $white;
			}

			img {
				width: 12px;
			}
		}
	}
}
