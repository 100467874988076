.app-layout-header {
	position: fixed;
	display: flex;
	align-items: center;
	left: 0;
	right: 0;
	top: 0;
	height: $header-height;
	min-width: 360px;
	background-color: $white;
	border-bottom: 1px solid $light-gray;
	z-index: 1001;

	& > * {
		vertical-align: top;
	}

	.burger-button-wrapper {
		@extend .flex-center;
		@extend .p-l-m;

		display: inline-flex;
		background-color: $primary;
		height: $header-height;
		width: $header-height + $space-m + $space-xs;
		margin-left: -$space-m;
	}
}

.side-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 300px;
	position: fixed;
	top: $header-height;
	bottom: 0;
	background-color: $light-gray;
}

.app-layout-menu {
	@extend .side-menu;
	@extend .p-s;

	left: 0;
	border-right: 1px solid $gray;
	z-index: 1002;

	.app-menu-logo {
		width: 100%;
	}
}

.app-layout-main {
	@extend .p-b-l;

	margin-top: $header-height;
	overflow: hidden;
	z-index: 1000;
}

.BurgerInner,
.BurgerInner:before,
.BurgerInner:after {
	background-color: $light-gray !important;
}

/* Position and sizing of burger button */
.bm-burger-button,
.bm-cross-button {
	display: none;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
	position: fixed;
	height: 100%;
	top: $header-height;
	background: $gray;
}

/* General sidebar styles */
.bm-menu {
	width: 100% !important;
	height: calc(100vh - $header-height) !important;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
	white-space: initial !important;
	overflow-y: auto !important;

	& > nav {
		height: auto !important;

		a {
			@extend .m-b-xs;

			display: block;
			color: $white !important;
			text-decoration: none;
			font-size: 1rem !important;
		}
	}

	* {
		outline: none !important;
	}
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
	top: $header-height;
}

// Bottom content
.bottom-content {
	position: absolute;
	bottom: 100px;
	left: 10%;
	width: 80%;
}
