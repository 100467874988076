.message-page {
	height: 100vh;

	.message-container {
		width: 80%;
		max-width: 450px;

		.btn {
			max-width: 150px;
		}
	}
}